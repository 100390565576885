import update from 'immutability-helper';

export interface TabConfig {
  id?: number;
}

export interface Config {
  activeId?: number;
  openedItems?: TabConfig[];
}

export const handleOpenTab = <A extends Config>(
  id: number,
  config: Config,
  setConfig: Function | undefined
): A => {
  const openedItems = config.openedItems ? [...config.openedItems] : [];

  if (!openedItems?.find((t) => t.id === id)) {
    openedItems?.push({id});
  }

  return setConfig?.(
    update(config, {
      activeId: {
        $set: id,
      },
      openedItems: {
        $set: openedItems,
      },
    })
  );
};

export const handleCloseTab = <A extends Config>(
  id: number | undefined,
  config: Config,
  setConfig: Function | undefined
): A => {
  const openedItems =
    config.openedItems?.filter((o: any) => o.id && o.id !== id) ?? [];

  let activeId = config.activeId;
  if (config.activeId === id) {
    activeId =
      config.openedItems && config.openedItems?.length > 1
        ? config.openedItems?.filter((e) => e.id !== id)[0]?.id
        : undefined;
  }

  return setConfig?.(
    update(config, {
      activeId: {
        $set: activeId,
      },
      openedItems: {
        $set: openedItems,
      },
    })
  );
};

export const handleChangeTab = <A extends Config>(
  id: number | undefined,
  config: Config,
  setConfig: Function | undefined
): A => {
  return setConfig?.(
    update(config, {
      activeId: {
        $set: id,
      },
    })
  );
};
