import {AlarmOutlined} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {AlarmGroup, AlarmModuleNode} from '../../interfaces/AlarmModuleNode';
import reduxSelectors from '../../redux/selectors';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import DataGrid, {DataGridColumn} from '../common/DataGrid';
import StatusSelect from '../selectors/StatusSelect';
import {AlarmGroupSections} from './AlarmGroupSections';

interface AlarmGroupItemUpsertProps {
  item?: AlarmGroup;
  onCancel?: Function;
  onSubmitted?: () => void;
}

interface AlarmGroupInputBody {
  name: string;
  status: 'active' | 'inactive';
  description: string;
  alarm_modules: number[];
  sections: number[];
}

const inputValidationSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required'),
  alarm_modules: yup
    .array()
    .min(1, 'Alarm modules should be selected to create the group.'),
});

const editValidationSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required'),
  alarm_modules: yup.array().min(1, 'Alarm modules are required.'),
});

export const AlarmGroupItemUpsert: React.FC<AlarmGroupItemUpsertProps> = ({
  item,
  onCancel,
  onSubmitted,
}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const rows = assets.alarm_modules;
  const formik = useFormik<AlarmGroupInputBody>({
    initialValues: {
      status: item?.status ?? 'active',
      name: item?.name ?? '',
      description: item?.description ?? '',
      alarm_modules: item?.alarm_modules?.map((it) => it.id) || [],
      sections: [],
    },
    validationSchema: item ? editValidationSchema : inputValidationSchema,
    onSubmit: async (data) => {
      const payload = Object.fromEntries(
        Object.entries(data).filter(([k, _]) => k !== 'sections')
      );
      setSubmittedInProgress(true);
      try {
        if (item) {
          const endpoint = `${apiBaseUrl}/alarm-module-group/${item.id}`;
          await API.patch<AlarmGroup>(endpoint, payload);
          enqueueSnackbar(`Alarm Group successfully updated!`, {
            variant: 'success',
            action: (key) => (
              <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
            ),
          });
        } else {
          const endpoint = `${apiBaseUrl}/alarm-module-group`;
          await API.post<AlarmGroup>(endpoint, payload);

          enqueueSnackbar(`Alarm Group [${data.name}] successfully created!`, {
            variant: 'success',
            action: (key) => (
              <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
            ),
          });
        }
        onSubmitted?.();
        onCancel?.();
      } catch (error) {
        const messages = getMessagesFromApiError(error);
        setSubmitErrors(messages);
      } finally {
        setSubmittedInProgress(false);
      }
    },
  });

  const columns: DataGridColumn<AlarmModuleNode>[] = [
    {
      field: 'select',
      type: 'select',
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Alarm',
      sortable: true,
      renderHeader: () => {
        return <AlarmOutlined />;
      },
      renderCell: ({row}) => {
        if (row.alarm) {
          return (
            <Tooltip title="Alarm">
              <AlarmOutlined color="error" />
            </Tooltip>
          );
        } else if (row.warning) {
          return (
            <Tooltip title="Warning">
              <AlarmOutlined color="warning" />
            </Tooltip>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      renderCell: ({row}) => {
        return row.name;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.status}</Box>;
      },
    },
    {
      field: 'company_id',
      headerName: 'Section',
      sortable: true,
      renderCell: ({row}) => {
        return (
          <Box whiteSpace="nowrap">
            {assets?.zones?.find((it) => it.id === row.zone_id)?.name}
          </Box>
        );
      },
    },
    {
      field: 'mac_address',
      headerName: 'Mac Address',
      sortable: true,
      renderCell: ({row}) => {
        return row.mac_address;
      },
    },
    {
      field: 'longitude',
      headerName: 'Lon',
      sortable: true,
      valueGetter: ({row}) => row.longitude,
    },
    {
      field: 'latitude',
      headerName: 'Lat',
      sortable: true,
      valueGetter: ({row}) => row.latitude,
    },
    {
      field: 'created_at',
      headerName: 'Timestamp',
      sortable: true,
      renderCell: ({row}) => {
        return dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss');
      },
    },
  ];

  const selectedItems = formik.values.alarm_modules ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => formik.values.alarm_modules?.includes(i.id)),
    [rows, formik.values.alarm_modules]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (formik.values.alarm_modules?.includes(id)) {
      formik.setFieldValue(
        'alarm_modules',
        formik.values.alarm_modules?.filter((i) => i !== id)
      );
    } else {
      formik.setFieldValue('alarm_modules', [
        ...formik.values.alarm_modules,
        id,
      ]);
    }
  };

  const selectAll = () => {
    formik.setFieldValue('alarm_modules', rows?.map((i) => i.id) ?? []);
  };

  const unselectAll = () => {
    formik.setFieldValue('alarm_modules', []);
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const preSelectItemsBySection = (sectionIds: number[]) => {
    const matchedModuleIds = assets.alarm_modules
      .filter((it) => sectionIds?.includes(it?.zone_id ?? -1))
      ?.map((it) => it.id);
    formik.setFieldValue('sections', sectionIds);
    formik.setFieldValue('alarm_modules', matchedModuleIds);
  };
  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      {submitErrors.map((error, idx) => (
        <Alert
          key={`error-c-${idx}`}
          severity="error"
          onClose={() => {
            setSubmitErrors([]);
          }}
        >
          {error}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name ?? ''}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />

        <TextField
          value={formik.values.description}
          label="Description"
          size="small"
          name="description"
          multiline
          rows={3}
          fullWidth
          onChange={formik.handleChange}
        />
        <AlarmGroupSections
          formik={formik}
          sections={formik.values.sections}
          sectionsList={assets.zones}
          fieldHandle={(fieldName, selectedId) => {
            if (formik.values.sections?.includes(selectedId)) {
              preSelectItemsBySection([
                ...formik.values.sections?.filter((it) => it !== selectedId),
              ]);
            } else {
              preSelectItemsBySection([...formik.values.sections, selectedId]);
            }
          }}
        />
        <Paper sx={{p: 3, mb: 3}}>
          <Box fontSize={20} lineHeight={1.6}>
            Alarm List
          </Box>

          {formik.values.sections?.length < 1 && (
            <FormHelperText
              error={
                !!formik.touched.alarm_modules && !!formik.errors.alarm_modules
              }
            >
              {formik.touched.alarm_modules && formik.errors.alarm_modules}
            </FormHelperText>
          )}
          <DataGrid
            sx={{
              marginTop: 2,
              td: {
                whiteSpace: 'nowrap',
                padding: '15px',
              },
            }}
            rows={rows}
            columns={columns}
            size="small"
            pagination
          />
        </Paper>

        <Box display="flex" justifyContent="end" gap={0.5}>
          <Button
            onClick={() => {
              onCancel?.();
            }}
          >
            Cancel
          </Button>

          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
              sx={{ml: 1}}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
