import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import orderBy from 'lodash/orderBy';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {
  GasMonitoringNode,
  GasMonitoringNodeListQuery,
  GasMonitoringNodeListResponse,
} from '../../../interfaces/GasMonitoringNode';
import reduxActions from '../../../redux/actions';
import reduxSelectors from '../../../redux/selectors';
import {
  handleChangeTab,
  handleCloseTab,
  handleOpenTab,
} from '../../../utils/tab';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {ResizableColumns} from '../../common/ResizableColumns';
import TabLabel from '../../common/TabLabel';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: AMSShortTermHistoryReportData;
  onUpdate?: (value?: AMSShortTermHistoryReportData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'alarm',
  'value',
  'unit_id',
  'type_id',
  'date',
  'battery_voltage',
  'external_voltage',
];

interface AMSShortTermHistoryReportDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: string[];
  selectAll?: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
    status?: 'active' | 'inactive' | 'unacknowledged' | 'all';
  };
}

export interface AMSShortTermHistoryReportData {
  activeId?: number;
  openedItems: AMSShortTermHistoryReportDataTab[];
}

export const getAMSShortTermHistoryReportData =
  (): AMSShortTermHistoryReportData => ({
    activeId: undefined,
    openedItems: [],
  });

export const getAMSShortTermHistoryReportDataTab = (
  id: number
): AMSShortTermHistoryReportDataTab => ({
  id,
  selectAll: true,
});

export const AMSShortTermHistoryReport = ({value, onUpdate}: Props) => {
  const reduxDispatch = useAppDispatch();

  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config = useMemo(() => {
    const v = value ?? getAMSShortTermHistoryReportData();
    return {
      ...v,
    };
  }, [value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const ams_nodes = useAppSelector(({assets}) =>
    assets.ams_nodes.filter((el) => el.ack === '1')
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex]
          ? config.openedItems[openedItemIndex]
          : getAMSShortTermHistoryReportDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const tabNames = useMemo(
    () =>
      config.openedItems.map(
        (o) => ams_nodes.find((i) => Number(i.id) === Number(o.id))?.name
      ),
    [config.openedItems, ams_nodes]
  );

  // get fresh assets
  useEffect(() => {
    reduxDispatch(reduxActions.assets.fetchGasMonitorNodes);
  }, [config.openedItems]);

  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const unitIdLabels = useAppSelector(({assets}) => assets.emoduleUnitIdLabel);

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<GasMonitoringNodeListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<GasMonitoringNodeListQuery | null>(
    () =>
      openedItem?.id
        ? {
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
            status: openedItem.params?.status ?? 'all',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: GasMonitoringNodeListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/ams/${config.activeId}/short-term-history`;
        const resp = await API.get<GasMonitoringNodeListResponse>(endpoint, {
          params,
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/

  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);
  const columns: DataGridColumn<any>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(`${row.id}`)}
          onChange={() => toggleSelectItem(`${row.id}`)}
        />
      ),
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => row.commtrac_external_id,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: true,
      valueGetter: ({row}) => row.value,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'unit_id',
      headerName: 'Units',
      sortable: true,
      valueGetter: ({row}) => unitIdLabels?.[row.unit_id] ?? '',
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'type_id',
      headerName: 'Type',
      sortable: true,
      valueGetter: ({row}) => typeIdLabels?.[row.type_id] ?? '',
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
      valueGetter: ({row}) => row.date,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'sensor_serial_number',
      headerName: 'Sensor SN',
      sortable: true,
      valueGetter: ({row}) => row.sensor_serial_number,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'battery_voltage',
      headerName: 'Battery Voltage',
      sortable: true,
      valueGetter: ({row}) => `${row.battery_voltage} V`,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'external_voltage',
      headerName: 'Ext Voltage',
      sortable: true,
      valueGetter: ({row}) => `${row.external_voltage} V`,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'power_source',
      headerName: 'Power Source',
      sortable: true,
      valueGetter: ({row}) => row.power_source,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'callibration_date',
      headerName: 'Calibration Date',
      sortable: true,
      valueGetter: ({row}) => row.callibration_date,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'module_absent',
      headerName: 'Module Absent',
      sortable: true,
      valueGetter: ({row}) => row.module_absent,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'module_warning',
      headerName: 'Module Warning',
      sortable: true,
      valueGetter: ({row}) => row.module_warning,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'point1_tripped',
      headerName: 'Point1 Tripped',
      sortable: true,
      valueGetter: ({row}) => row.point1_tripped,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'point2_tripped',
      headerName: 'Point2 Tripped',
      sortable: true,
      valueGetter: ({row}) => row.point2_tripped,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'stel_alarm',
      headerName: 'Stel Alarm',
      sortable: true,
      valueGetter: ({row}) => row.stel_alarm,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'twa_alarm',
      headerName: 'Twa Alarm',
      sortable: true,
      valueGetter: ({row}) => row.twa_alarm,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'fault',
      headerName: 'Fault',
      sortable: true,
      valueGetter: ({row}) => row.fault,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'pellister_over',
      headerName: 'Pellister Over',
      sortable: true,
      valueGetter: ({row}) => row.pellister_over,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'input1',
      headerName: 'Input1',
      sortable: true,
      valueGetter: ({row}) => row.input1,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'input2',
      headerName: 'Input2',
      sortable: true,
      valueGetter: ({row}) => row.input2,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'output1',
      headerName: 'Output1',
      sortable: true,
      valueGetter: ({row}) => row.output1,
      getSxCell: ({row}) => getBorderColor(row),
    },
    {
      field: 'output2',
      headerName: 'Output2',
      sortable: true,
      valueGetter: ({row}) => row.output2,
      getSxCell: ({row}) => getBorderColor(row),
    },
  ];

  const getBorderColor = (row: GasMonitoringNode & {event_type: number}) => {
    const isAlarm = row?.event_type === 402; // Alarm event type (row border color red)
    const isAlert = row?.event_type === 403; // Alert event type (row border color yellow)
    const color = isAlarm ? 'red' : isAlert ? 'yellow' : 'rgba(81, 81, 81, 1)';
    const sx: any =
      isAlarm || isAlert
        ? {
            borderBottom: `1px solid ${color}`,
            borderTop: `1px solid ${color}`,
            color: color,
          }
        : {};
    if (!isDarkMode && color === 'yellow') {
      sx.textShadow = '0.5px 0.5px 0.5px #555';
    }
    return sx;
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = useMemo(
    () =>
      openedItem?.selectAll
        ? rows.map((it) => it.id.toString())
        : openedItem?.selectedIds ?? [],
    [rows, openedItem]
  );

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems?.includes(`${i.id}`)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i) => `${i.id}`) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const dateTimeFormatter = (timestamp: string) => {
    return dayjs(timestamp).format('ddd, MMM D, YYYY');
  };

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);

  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);

  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  const chartData = useMemo(
    () => orderBy(selectedRows, (i) => i.date),
    [selectedRows]
  );

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if ((prevSelectedAll && !selectedAll) || !openedItem?.selectedIds) {
      selectAll();
    }
  }, [rows]);

  const openTab = (id: number) => {
    return handleOpenTab(id, config, onUpdate);
  };

  const closeTab = (id: number) => {
    return handleCloseTab(id, config, onUpdate);
  };

  const changeTab = (_: any, id: number) => {
    handleChangeTab(id, config, onUpdate);
  };

  return (
    <>
      <DashboardPanelTitleSlot>
        AMS Short Term History Report
      </DashboardPanelTitleSlot>

      <ResizableColumns
        left={
          <Box
            width="100%"
            height="100%"
            minWidth={400}
            pt={2}
            pb={2}
            pr={6}
            bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData} margin={{left: 20, top: 40}}>
                <XAxis dataKey="date" />
                <YAxis>
                  <Label value="Latency (ms)" dx={-30} angle={-90} />
                </YAxis>
                <RechartsTooltip
                  labelStyle={{color: 'black'}}
                  labelFormatter={dateTimeFormatter}
                />
                <CartesianGrid opacity={0.3} />
                <Legend onClick={(item) => toggleKey(item.dataKey)} />
                <Line dataKey="value" name="Value" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            py={1.5}
            bgcolor={(theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
            }
          >
            {isOpenAwayFromConnectView && (
              <Box px={1.5}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button variant="outlined" {...bindTrigger(popupState)}>
                        Select an Object
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {ams_nodes?.map((it) => (
                          <MenuItem
                            key={it.id}
                            onClick={() => {
                              it?.id && openTab(it.id);
                              popupState.close();
                            }}
                          >
                            {it.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {config.openedItems.length ? (
              <Box px={2}>
                <Tabs
                  value={openedItem?.id}
                  variant="scrollable"
                  onChange={changeTab}
                >
                  {config.openedItems.map((i, idx) => (
                    <Tab
                      key={i.id}
                      value={i.id}
                      label={
                        <TabLabel
                          name={tabNames?.[idx] ?? ''}
                          onClose={() => closeTab(i.id)}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <Box minWidth={400} px={1.5}>
                <Alert severity="warning">No Assets Opened</Alert>
              </Box>
            )}

            <Box display="flex" flexDirection="column" px={1.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <Box minWidth={400}>
                    <DateRangeSelect
                      value={
                        openedItem
                          ? [
                              dayjs(openedItem.params?.date_start).toDate(),
                              dayjs(openedItem.params?.date_end).toDate(),
                            ]
                          : undefined
                      }
                      size="small"
                      disabled={!openedItem}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    selectAll: true,
                                    params: {
                                      ...openedItem.params,
                                      date_start: v?.[0]
                                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                        : undefined,
                                      date_end: v?.[0]
                                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                        : undefined,
                                    },
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <ButtonGroup disabled={!openedItem}>
                    {/*
                    This will be the AWSLongTermHistoryExportButton

                    <Button size="small">
                      <CommtracNodeHistoryExportButton
                        componentProps={{
                          ...value,
                          id: value?.activeId,
                          date_start: openedItem?.params?.date_start,
                          date_end: openedItem?.params?.date_end,
                        }}
                      />
                    </Button> */}

                    <Button
                      size="small"
                      onClick={() => params && fetchData(params)}
                    >
                      <RefreshIcon />
                    </Button>

                    <AutoRefreshSelect
                      value={openedItem?.refreshInterval ?? null}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    refreshInterval: v,
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />

                    <Button
                      size="small"
                      onClick={() => dataGridRef.current?.printTable()}
                    >
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <Backdrop
            open={fetchedInProgress}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors.map((error, idx) => (
            <Alert
              key={idx}
              severity="error"
              onClose={() => params && fetchData(params)}
            >
              {error}
            </Alert>
          ))}

          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            loading={fetchedInProgress}
            shownFields={shownFields}
            pagination
            paginationMode="server"
            size="small"
            sortBy={
              params?.order
                ? {
                    field: params?.order,
                    dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                  }
                : null
            }
            sortingMode="server"
            page={params?.page}
            pageSize={params?.limit}
            rowCount={fetchedData?.count}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            onPageChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onPageSizeChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onSort={(v) => {
              if (v && openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          params: {
                            ...openedItem.params,
                            order: v.field,
                            dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onShownFieldsChange={setShownFields}
          />
        </Box>
      </ResizableColumns>
    </>
  );
};
