/* eslint simple-import-sort/imports: 0 */
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {
  AMSEModuleCalibrationHistoryQuery,
  AMSEModuleCalibrationHistoryResponse,
} from '../../../interfaces/AMSEModuleCalibrationHistory';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import TabLabel from '../../common/TabLabel';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {amsSensorAddressMask} from '../../../interfaces/GasMonitoringNode';
import reduxActions from '../../../redux/actions';
import {AmsEmoduleObjectSelect} from '../../selectors/AmsEmoduleObjectSelect';
import {
  handleChangeTab,
  handleCloseTab,
  handleOpenTab,
} from '../../../utils/tab';

interface Props {
  value?: AMSEModuleCalibrationHistoryData;
  onUpdate?: (value?: AMSEModuleCalibrationHistoryData) => void;
}

interface AMSEModuleCalibrationHistoryDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: string[];
  selectAll?: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
    status?: 'active' | 'inactive' | 'unacknowledged' | 'all';
  };
}

export interface AMSEModuleCalibrationHistoryData {
  activeId?: number;
  openedItems: AMSEModuleCalibrationHistoryDataTab[];
}

export const getAMSEModuleCalibrationHistoryData =
  (): AMSEModuleCalibrationHistoryData => ({
    activeId: undefined,
    openedItems: [],
  });

const getAMSEModuleCalibrationHistoryDataTab = (
  id: number
): AMSEModuleCalibrationHistoryDataTab => ({
  id,
  selectAll: true,
  params: {
    date_start: dayjs().format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  },
});

const DEFAULT_SHOWN_FIELDS = [
  'name',
  'commtrac_external_id',
  'date_calibration',
  'calibration_value',
];

export const AMSEModuleCalibrationHistory = ({value, onUpdate}: Props) => {
  const reduxDispatch = useAppDispatch();

  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config = useMemo(() => {
    const v = value ?? getAMSEModuleCalibrationHistoryData();
    return {
      ...v,
    };
  }, [value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex] &&
          config.openedItems[openedItemIndex].params
          ? config.openedItems[openedItemIndex]
          : getAMSEModuleCalibrationHistoryDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );
  const amsEmodules = useAppSelector(({assets}) => assets.ams_emodules);

  const tabNames = useMemo(() => {
    return config.openedItems.map(
      (o) =>
        `E-module #${amsEmodules.find((i) => Number(i.id) === Number(o.id))?.serial_number ?? 'unknown'}`
    );
  }, [config.openedItems, amsEmodules]);

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<AMSEModuleCalibrationHistoryResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<AMSEModuleCalibrationHistoryQuery | null>(
    () =>
      openedItem?.id
        ? {
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
            status: openedItem.params?.status ?? 'all',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: AMSEModuleCalibrationHistoryQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/ams/emodule/${config.activeId}/calibration-history`;
        const resp = await API.get<AMSEModuleCalibrationHistoryResponse>(
          endpoint,
          {
            params,
          }
        );
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  useEffect(() => {
    reduxDispatch(reduxActions.assets.fetchAmsEmodules);
  }, [config.openedItems]);

  /****************/
  /* auto refresh */
  /****************/

  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);
  const columns: DataGridColumn<any>[] = [
    {
      field: 'name',
      headerName: 'Sentro 1 Name',
      sortable: true,
      valueGetter: ({row}) => row.name,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Sentro 1 Network ID',
      sortable: true,
      // eslint-disable-next-line no-bitwise
      valueGetter: ({row}) => row.commtrac_external_id & amsSensorAddressMask,
    },
    {
      field: 'date_calibration',
      headerName: 'Calibration Date',
      sortable: true,
      renderCell: ({row}) => {
        return row.date_calibration ? row.date_calibration.slice(0, 10) : '';
      },
    },
    {
      field: 'calibration_value',
      headerName: 'Calibration Value',
      sortable: true,
      valueGetter: ({row}) => row.calibration_value,
    },
    {
      field: 'pos_lat',
      headerName: 'Lat',
      sortable: true,
      valueGetter: ({row}) => row.pos_lat,
    },
    {
      field: 'pos_lon',
      headerName: 'Lon',
      sortable: true,
      valueGetter: ({row}) => row.pos_lon,
    },
  ];

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = useMemo(
    () =>
      openedItem?.selectAll && rows.length > 0
        ? rows.filter((it) => !!it.id).map((it) => it.id.toString())
        : openedItem?.selectedIds ?? [],
    [rows, openedItem]
  );

  const selectedRows = useMemo(() => {
    const theseItems = rows.filter((i) => selectedItems?.includes(`${i.id}`));

    return theseItems;
  }, [rows, selectedItems]);

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i) => `${i.id}`) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const openTab = (id: number) => {
    return handleOpenTab(id, config, onUpdate);
  };

  const closeTab = (id: number) => {
    return handleCloseTab(id, config, onUpdate);
  };

  const changeTab = (_: any, id: number) => {
    handleChangeTab(id, config, onUpdate);
  };

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  return (
    <>
      <DashboardPanelTitleSlot>
        AMS eModule Calibration History Report
      </DashboardPanelTitleSlot>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="hidden"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          py={1.5}
          bgcolor={(theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
          }
        >
          {isOpenAwayFromConnectView && (
            <Box sx={{marginLeft: 1.5}}>
              <AmsEmoduleObjectSelect onChange={openTab} />
            </Box>
          )}
          {config.openedItems.length ? (
            <Box px={2}>
              <Tabs
                value={openedItem?.id}
                variant="scrollable"
                onChange={changeTab}
              >
                {config.openedItems.map((i: any, idx: number) => (
                  <Tab
                    key={i.id}
                    value={i.id}
                    label={
                      <TabLabel
                        name={tabNames?.[idx] ?? ''}
                        onClose={() => closeTab(i.id)}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Box>
          ) : (
            <Box minWidth={400} px={1.5}>
              <Alert severity="warning">No e-module object selected</Alert>
            </Box>
          )}

          <Box display="flex" flexDirection="column" px={1.5}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Box display="flex" gap={1}>
                <Box minWidth={400}>
                  <DateRangeSelect
                    value={
                      openedItem
                        ? [
                            dayjs(openedItem.params?.date_start).toDate(),
                            dayjs(openedItem.params?.date_end).toDate(),
                          ]
                        : undefined
                    }
                    size="small"
                    disabled={!openedItem}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  selectAll: true,
                                  params: {
                                    ...openedItem.params,
                                    date_start: v?.[0]
                                      ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                      : undefined,
                                    date_end: v?.[0]
                                      ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                      : undefined,
                                  },
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />
                </Box>
              </Box>

              <Box display="flex">
                <ButtonGroup>
                  <Button
                    size="small"
                    onClick={() => params && fetchData(params)}
                  >
                    <RefreshIcon />
                  </Button>

                  <AutoRefreshSelect
                    value={openedItem?.refreshInterval ?? null}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  refreshInterval: v,
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />

                  <Button
                    size="small"
                    onClick={() => dataGridRef.current?.printTable()}
                  >
                    <PrintIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
        </Box>

        <Backdrop
          open={fetchedInProgress}
          sx={{position: 'absolute', zIndex: 1199}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, idx) => (
          <Alert
            key={idx}
            severity="error"
            onClose={() => params && fetchData(params)}
          >
            {error}
          </Alert>
        ))}

        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          shownFields={shownFields}
          pagination
          paginationMode="server"
          size="small"
          sortBy={
            params?.order
              ? {
                  field: params?.order,
                  dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                }
              : null
          }
          sortingMode="server"
          page={params?.page}
          pageSize={params?.limit}
          rowCount={fetchedData?.count}
          sxFooter={{
            bgcolor: (theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
          }}
          onPageChange={(v) => {
            if (openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        selectAll: true,
                        params: {
                          ...openedItem.params,
                          page: v,
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onPageSizeChange={(v) => {
            if (openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        selectAll: true,
                        params: {
                          ...openedItem.params,
                          page: 0,
                          limit: v,
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onSort={(v) => {
            if (v && openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        params: {
                          ...openedItem.params,
                          order: v.field,
                          dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onShownFieldsChange={setShownFields}
        />
      </Box>
      {/* </ResizableColumns> */}
    </>
  );
};
