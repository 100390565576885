import {Alarm} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {AlarmModuleNode} from '../../interfaces/AlarmModuleNode';
import {tweakAlarmNameWithMacAddress} from '../../utils/macAddress';
import {MapLatLangCoordinates} from '../common/Map';
import AlarmAcknowledge from './AlarmAcknowledge';
import AlarmItemUpdate from './AlarmItemUpdate';
import AlarmItemView from './AlarmItemView';

interface Props {
  pk: number;
  item?: AlarmModuleNode;
  mode?: 'view' | 'update' | 'chat' | 'ack' | 'update_from_info';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (item: AlarmModuleNode, type: 'alarm' | 'alarm_log') => void;
}

const AlarmItem = ({
  pk,
  item,
  mode: initialMode = 'view',
  locationCoordinates,
  isActiveModal,
  prefetch,
  onClose,
  onSubmitted,
  onOpenHistory,
  onPurged,
}: //

Props) => {
  const [mode, setMode] = useState(initialMode);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<AlarmModuleNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<AlarmModuleNode>(
        `${apiBaseUrl}/alarm-module/${pk}`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (item && !isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <Alarm sx={{color: 'primary.main'}} />
          {fetchedData ? (
            <Box fontSize={24}>
              {tweakAlarmNameWithMacAddress(
                fetchedData.name ?? '',
                fetchedData.mac_address ?? ''
              )}
            </Box>
          ) : null}
        </Box>
        <Box display="flex" gap={0.5}>
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
          {onClose ? (
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}
        </Alert>
      ))}
      {mode === 'update' || mode === 'update_from_info' ? (
        <AlarmItemUpdate
          mode={mode}
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          locationCoordinates={locationCoordinates}
          isActiveModal={isActiveModal}
          onCancel={() => {
            mode === 'update_from_info' ? setMode('view') : onClose?.();
          }}
          onSubmitted={() => {
            onSubmitted?.();
            mode === 'update_from_info' ? setMode('view') : onClose?.();
            fetchData();
          }}
        />
      ) : mode === 'ack' ? (
        <AlarmAcknowledge
          item={fetchedData}
          onSubmitted={() => {
            onSubmitted?.();
            fetchData();
          }}
          onCancel={onClose}
        />
      ) : (
        <AlarmItemView
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onPurged={onPurged}
          onCancel={onClose}
          onEdit={() => setMode('update_from_info')}
          onAck={() => {
            setMode('ack');
          }}
          onFetched={setFetchedData}
          onOpenHistory={onOpenHistory}
          onSubmitted={() => {
            onSubmitted?.();
            fetchData();
          }}
        />
      )}
    </Box>
  );
};

export default AlarmItem;
