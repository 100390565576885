import AdjustIcon from '@mui/icons-material/Adjust';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import BuildIcon from '@mui/icons-material/Build';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import SyncIcon from '@mui/icons-material/Sync';
import {SvgIcon, SvgIconProps} from '@mui/material';

export const eventIconsLegacy = {
  nodes: {
    cnManyHeartbeat: <SignalWifiStatusbar4BarIcon color="error" />,
    cnNoHeartbeat: <SignalWifi0BarIcon color="error" />,
    cnFewHeartbeat: <SignalWifi1BarIcon color="warning" />,
    wifiDisconnected: <SignalWifi0BarIcon color="error" />,
    cnUnregisteredNetwork: <AlignVerticalBottomIcon />,
    cnAssignedMaster: <MergeTypeIcon />,
    cnConfigurationMessageAck: <CheckIcon color="success" />,
    cnConfigurationMessageNack: <SyncIcon color="error" />,
    cnConfigurationMessageFailed: <BuildIcon color="error" />,
    cnAck: <CheckIcon color="success" />,
    cnVersionChanged: <AllInclusiveIcon />,
    externalPower: <PowerSettingsNewIcon />,
  },
  commtracNode: {
    alarm: <ReportProblemIcon color="error" />,
    manyLocation: <SignalWifiStatusbar4BarIcon color="error" />,
    noLocation: <SignalWifi0BarIcon color="error" />,
    derivedLocation: <SignalWifi1BarIcon color="warning" />,
    minerNotMoving: <PersonRemoveIcon color="error" />,
    minerOkMoving: <PersonAddIcon color="error" />,
    minerMsgConfFailed: <BuildIcon color="error" />,
    minerAck: <CheckIcon color="success" />,
    minerNack: <SyncIcon color="error" />,
    minerEbm: <AdjustIcon color="error" />,
    minerAnswerNack: <RadioButtonUncheckedIcon />,
    minerAnswerAck: <CircleIcon color="disabled" />,
    minerAnswerRead: <CircleIcon />,
    employeeEmergencyResponseOk: <CircleIcon color="success" />,
    employeeEmergencyResponseInjuredButOk: <CircleIcon color="warning" />,
    employeeEmergencyResponseInjuredAndNeedsHelp: <CircleIcon color="error" />,
  },
};

export const eventIcons = {
  messages: {
    nack: {
      Icon: RadioButtonUncheckedIcon,
    },
    ack: {
      Icon: CircleIcon,
      props: {
        color: 'disabled',
      },
    },
    read: {
      Icon: CircleIcon,
    },
    ans: {
      Icon: CircleIcon,
      props: {
        color: 'success',
      },
    },
  },
} as const;

export const connectionStatusIcons = {
  verygood: <CircleIcon color="success" sx={{transform: 'scale(0.6)'}} />,
  good: <CircleIcon color="success" sx={{transform: 'scale(0.6)'}} />,
  slow: <CircleIcon color="warning" sx={{transform: 'scale(0.6)'}} />,
  veryslow: <CircleIcon color="warning" sx={{transform: 'scale(0.6)'}} />,
  disconnected: <CircleIcon color="error" sx={{transform: 'scale(0.6)'}} />,
} as const;

export const processStatusIcons = {
  running: <CircleIcon color="success" sx={{transform: 'scale(0.6)'}} />,
  stopped: <CircleIcon color="error" sx={{transform: 'scale(0.6)'}} />,
} as const;

export const renderEventIcon = (
  {
    Icon,
    props,
  }: {
    Icon: typeof SvgIcon;
    props?: SvgIconProps;
  },
  otherProps: SvgIconProps
) => {
  return <Icon {...otherProps} {...props} />;
};
