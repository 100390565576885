import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {Box, Button, Divider, IconButton, Tooltip} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import ModalFixed from '../common/ModalFixed';
import AlarmExport from './AlarmExport';
import AlarmImport from './AlarmImport';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
}

const AlarmModuleImportExportButton = <T extends ComponentType>({
  component,
  componentProps,
  children,
  onSubmitted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Tooltip title="Import/Export">
        <Component {...componentProps} onClick={() => setIsOpened(true)}>
          {children}
        </Component>
      </Tooltip>

      {isOpened ? (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" width="100%" gap={1.5}>
                <ImportExportIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Import/Export Alarm Modules</Box>
              </Box>
              <IconButton onClick={() => setIsOpened(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              position="relative"
              gap={3}
            >
              <Divider>Export</Divider>
              <AlarmExport />
              <Divider>Import</Divider>
              <AlarmImport onSubmitted={onSubmitted} />
            </Box>
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default AlarmModuleImportExportButton;
