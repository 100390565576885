import {Icon} from 'leaflet';
import mapValues from 'lodash/mapValues';
import {cloneElement} from 'react';

interface ColoredIcons {
  [k: string]: any;
}

export const COLORED_ICONS = {
  communicationNodes: {
    default: require('../images/communication-nodes/communication-node-default.png'),
    blue: require('../images/communication-nodes/communication-node-blue.png'),
    brown: require('../images/communication-nodes/communication-node-brown.png'),
    cornsilk: require('../images/communication-nodes/communication-node-cornsilk.png'),
    cyan: require('../images/communication-nodes/communication-node-cyan.png'),
    green: require('../images/communication-nodes/communication-node-green.png'),
    dark_blue: require('../images/communication-nodes/communication-node-dark_blue.png'),
    gold: require('../images/communication-nodes/communication-node-gold.png'),
    gray: require('../images/communication-nodes/communication-node-gray.png'),
    orchid: require('../images/communication-nodes/communication-node-orchid.png'),
    pink: require('../images/communication-nodes/communication-node-pink.png'),
    red: require('../images/communication-nodes/communication-node-red.png'),
    yellow: require('../images/communication-nodes/communication-node-yellow.png'),
    orange: require('../images/communication-nodes/communication-node-orange.png'),
    duplicate: require('../images/other/duplicate_icon.png'),
  } as ColoredIcons,
  miners: {
    default: require('../images/miners/miner-default.png'),
    blue: require('../images/miners/miner-blue.png'),
    brown: require('../images/miners/miner-brown.png'),
    cornsilk: require('../images/miners/miner-cornsilk.png'),
    cyan: require('../images/miners/miner-cyan.png'),
    green: require('../images/miners/miner-green.png'),
    dark_blue: require('../images/miners/miner-dark_blue.png'),
    gold: require('../images/miners/miner-gold.png'),
    gray: require('../images/miners/miner-gray.png'),
    orchid: require('../images/miners/miner-orchid.png'),
    pink: require('../images/miners/miner-pink.png'),
    red: require('../images/miners/miner-red.png'),
    yellow: require('../images/miners/miner-yellow.png'),
    orange: require('../images/miners/miner-orange.png'),
  } as ColoredIcons,
  assetEvents: {
    default: require('../images/asset-events/asset-event-default.png'),
    blue: require('../images/asset-events/asset-event-blue.png'),
    brown: require('../images/asset-events/asset-event-brown.png'),
    cornsilk: require('../images/asset-events/asset-event-cornsilk.png'),
    cyan: require('../images/asset-events/asset-event-cyan.png'),
    green: require('../images/asset-events/asset-event-green.png'),
    dark_blue: require('../images/asset-events/asset-event-dark_blue.png'),
    gold: require('../images/asset-events/asset-event-gold.png'),
    gray: require('../images/asset-events/asset-event-gray.png'),
    orchid: require('../images/asset-events/asset-event-orchid.png'),
    pink: require('../images/asset-events/asset-event-pink.png'),
    red: require('../images/asset-events/asset-event-red.png'),
    yellow: require('../images/asset-events/asset-event-yellow.png'),
    orange: require('../images/asset-events/asset-event-orange.png'),
  } as ColoredIcons,
  assets: {
    default: require('../images/assets/asset-default.png'),
    blue: require('../images/assets/asset-blue.png'),
    brown: require('../images/assets/asset-brown.png'),
    cornsilk: require('../images/assets/asset-cornsilk.png'),
    cyan: require('../images/assets/asset-cyan.png'),
    green: require('../images/assets/asset-green.png'),
    dark_blue: require('../images/assets/asset-dark_blue.png'),
    gold: require('../images/assets/asset-gold.png'),
    gray: require('../images/assets/asset-gray.png'),
    orchid: require('../images/assets/asset-orchid.png'),
    pink: require('../images/assets/asset-pink.png'),
    red: require('../images/assets/asset-red.png'),
    yellow: require('../images/assets/asset-yellow.png'),
    orange: require('../images/assets/asset-orange.png'),
  } as ColoredIcons,
  fans: {
    healthy: require('../images/fans/fan-healthy.png'),
    blue: require('../images/fans/fan-blue.png'),
    brown: require('../images/fans/fan-brown.png'),
    cornsilk: require('../images/fans/fan-cornsilk.png'),
    cyan: require('../images/fans/fan-cyan.png'),
    green: require('../images/fans/fan-green.png'),
    dark_blue: require('../images/fans/fan-dark_blue.png'),
    gold: require('../images/fans/fan-gold.png'),
    gray: require('../images/fans/fan-gray.png'),
    orchid: require('../images/fans/fan-orchid.png'),
    pink: require('../images/fans/fan-pink.png'),
    red: require('../images/fans/fan-red.png'),
    yellow: require('../images/fans/fan-yellow.png'),
    orange: require('../images/fans/fan-orange.png'),
  } as ColoredIcons,
  belts: {
    healthy: require('../images/belts/belt-healthy.png'),
    blue: require('../images/belts/belt-blue.png'),
    brown: require('../images/belts/belt-brown.png'),
    cornsilk: require('../images/belts/belt-cornsilk.png'),
    cyan: require('../images/belts/belt-cyan.png'),
    green: require('../images/belts/belt-green.png'),
    dark_blue: require('../images/belts/belt-dark_blue.png'),
    gold: require('../images/belts/belt-gold.png'),
    gray: require('../images/belts/belt-gray.png'),
    orchid: require('../images/belts/belt-orchid.png'),
    pink: require('../images/belts/belt-pink.png'),
    red: require('../images/belts/belt-red.png'),
    yellow: require('../images/belts/belt-yellow.png'),
    orange: require('../images/belts/belt-orange.png'),
  } as ColoredIcons,
  amsUnknown: {
    health: require('../images/ams/unknown/ams-unknown-health.png'),
    blue: require('../images/ams/unknown/ams-unknown-blue.png'),
    brown: require('../images/ams/unknown/ams-unknown-brown.png'),
    cornsilk: require('../images/ams/unknown/ams-unknown-cornsilk.png'),
    cyan: require('../images/ams/unknown/ams-unknown-cyan.png'),
    green: require('../images/ams/unknown/ams-unknown-green.png'),
    dark_blue: require('../images/ams/unknown/ams-unknown-dark_blue.png'),
    gold: require('../images/ams/unknown/ams-unknown-gold.png'),
    gray: require('../images/ams/unknown/ams-unknown-gray.png'),
    orchid: require('../images/ams/unknown/ams-unknown-orchid.png'),
    pink: require('../images/ams/unknown/ams-unknown-pink.png'),
    red: require('../images/ams/unknown/ams-unknown-red.png'),
    yellow: require('../images/ams/unknown/ams-unknown-yellow.png'),
    orange: require('../images/ams/unknown/ams-unknown-orange.png'),
  } as ColoredIcons,

  AMB: {
    blue: require('../images/ams/sensors/AMB/AMB-Blue.png'),
    brown: require('../images/ams/sensors/AMB/AMB-Brown.png'),
    cornsilk: require('../images/ams/sensors/AMB/AMB-Cornsilk.png'),
    cyan: require('../images/ams/sensors/AMB/AMB-Cyan.png'),
    dark_blue: require('../images/ams/sensors/AMB/AMB-DarkBlue.png'),
    gold: require('../images/ams/sensors/AMB/AMB-Gold.png'),
    gray: require('../images/ams/sensors/AMB/AMB-Gray.png'),
    green: require('../images/ams/sensors/AMB/AMB-Green.png'),
    orchid: require('../images/ams/sensors/AMB/AMB-Orchid.png'),
    pink: require('../images/ams/sensors/AMB/AMB-Pink.png'),
    red: require('../images/ams/sensors/AMB/AMB-Red.png'),
    yellow: require('../images/ams/sensors/AMB/AMB-Yellow.png'),
  } as ColoredIcons,
  FLOW: {
    blue: require('../images/ams/sensors/FLOW/FLOW-Blue.png'),
    brown: require('../images/ams/sensors/FLOW/FLOW-Brown.png'),
    cornsilk: require('../images/ams/sensors/FLOW/FLOW-Cornsilk.png'),
    cyan: require('../images/ams/sensors/FLOW/FLOW-Cyan.png'),
    dark_blue: require('../images/ams/sensors/FLOW/FLOW-DarkBlue.png'),
    gold: require('../images/ams/sensors/FLOW/FLOW-Gold.png'),
    gray: require('../images/ams/sensors/FLOW/FLOW-Gray.png'),
    green: require('../images/ams/sensors/FLOW/FLOW-Green.png'),
    orchid: require('../images/ams/sensors/FLOW/FLOW-Orchid.png'),
    pink: require('../images/ams/sensors/FLOW/FLOW-Pink.png'),
    red: require('../images/ams/sensors/FLOW/FLOW-Red.png'),
    yellow: require('../images/ams/sensors/FLOW/FLOW-Yellow.png'),
  } as ColoredIcons,
  ANA: {
    blue: require('../images/ams/sensors/ANA/ANA-Blue.png'),
    brown: require('../images/ams/sensors/ANA/ANA-Brown.png'),
    cornsilk: require('../images/ams/sensors/ANA/ANA-Cornsilk.png'),
    cyan: require('../images/ams/sensors/ANA/ANA-Cyan.png'),
    dark_blue: require('../images/ams/sensors/ANA/ANA-DarkBlue.png'),
    gold: require('../images/ams/sensors/ANA/ANA-Gold.png'),
    gray: require('../images/ams/sensors/ANA/ANA-Gray.png'),
    green: require('../images/ams/sensors/ANA/ANA-Green.png'),
    orchid: require('../images/ams/sensors/ANA/ANA-Orchid.png'),
    pink: require('../images/ams/sensors/ANA/ANA-Pink.png'),
    red: require('../images/ams/sensors/ANA/ANA-Red.png'),
    yellow: require('../images/ams/sensors/ANA/ANA-Yellow.png'),
  } as ColoredIcons,
  CH4: {
    blue: require('../images/ams/sensors/CH4/CH4-Blue.png'),
    brown: require('../images/ams/sensors/CH4/CH4-Brown.png'),
    cornsilk: require('../images/ams/sensors/CH4/CH4-Cornsilk.png'),
    cyan: require('../images/ams/sensors/CH4/CH4-Cyan.png'),
    dark_blue: require('../images/ams/sensors/CH4/CH4-DarkBlue.png'),
    gold: require('../images/ams/sensors/CH4/CH4-Gold.png'),
    gray: require('../images/ams/sensors/CH4/CH4-Gray.png'),
    green: require('../images/ams/sensors/CH4/CH4-Green.png'),
    orchid: require('../images/ams/sensors/CH4/CH4-Orchid.png'),
    pink: require('../images/ams/sensors/CH4/CH4-Pink.png'),
    red: require('../images/ams/sensors/CH4/CH4-Red.png'),
    yellow: require('../images/ams/sensors/CH4/CH4-Yellow.png'),
  } as ColoredIcons,
  CL2: {
    blue: require('../images/ams/sensors/CL2/CL2-Blue.png'),
    brown: require('../images/ams/sensors/CL2/CL2-Brown.png'),
    cornsilk: require('../images/ams/sensors/CL2/CL2-Cornsilk.png'),
    cyan: require('../images/ams/sensors/CL2/CL2-Cyan.png'),
    dark_blue: require('../images/ams/sensors/CL2/CL2-DarkBlue.png'),
    gold: require('../images/ams/sensors/CL2/CL2-Gold.png'),
    gray: require('../images/ams/sensors/CL2/CL2-Gray.png'),
    green: require('../images/ams/sensors/CL2/CL2-Green.png'),
    orchid: require('../images/ams/sensors/CL2/CL2-Orchid.png'),
    pink: require('../images/ams/sensors/CL2/CL2-Pink.png'),
    red: require('../images/ams/sensors/CL2/CL2-Red.png'),
    yellow: require('../images/ams/sensors/CL2/CL2-Yellow.png'),
  } as ColoredIcons,
  CO: {
    blue: require('../images/ams/sensors/CO/CO-Blue.png'),
    brown: require('../images/ams/sensors/CO/CO-Brown.png'),
    cornsilk: require('../images/ams/sensors/CO/CO-Cornsilk.png'),
    cyan: require('../images/ams/sensors/CO/CO-Cyan.png'),
    dark_blue: require('../images/ams/sensors/CO/CO-DarkBlue.png'),
    gold: require('../images/ams/sensors/CO/CO-Gold.png'),
    gray: require('../images/ams/sensors/CO/CO-Gray.png'),
    green: require('../images/ams/sensors/CO/CO-Green.png'),
    orchid: require('../images/ams/sensors/CO/CO-Orchid.png'),
    pink: require('../images/ams/sensors/CO/CO-Pink.png'),
    red: require('../images/ams/sensors/CO/CO-Red.png'),
    yellow: require('../images/ams/sensors/CO/CO-Yellow.png'),
  } as ColoredIcons,
  H2: {
    blue: require('../images/ams/sensors/H2/H2-Blue.png'),
    brown: require('../images/ams/sensors/H2/H2-Brown.png'),
    cornsilk: require('../images/ams/sensors/H2/H2-Cornsilk.png'),
    cyan: require('../images/ams/sensors/H2/H2-Cyan.png'),
    dark_blue: require('../images/ams/sensors/H2/H2-DarkBlue.png'),
    gold: require('../images/ams/sensors/H2/H2-Gold.png'),
    gray: require('../images/ams/sensors/H2/H2-Gray.png'),
    green: require('../images/ams/sensors/H2/H2-Green.png'),
    orchid: require('../images/ams/sensors/H2/H2-Orchid.png'),
    pink: require('../images/ams/sensors/H2/H2-Pink.png'),
    red: require('../images/ams/sensors/H2/H2-Red.png'),
    yellow: require('../images/ams/sensors/H2/H2-Yellow.png'),
  } as ColoredIcons,
  H2S: {
    blue: require('../images/ams/sensors/H2S/H2S-Blue.png'),
    brown: require('../images/ams/sensors/H2S/H2S-Brown.png'),
    cornsilk: require('../images/ams/sensors/H2S/H2S-Cornsilk.png'),
    cyan: require('../images/ams/sensors/H2S/H2S-Cyan.png'),
    dark_blue: require('../images/ams/sensors/H2S/H2S-DarkBlue.png'),
    gold: require('../images/ams/sensors/H2S/H2S-Gold.png'),
    gray: require('../images/ams/sensors/H2S/H2S-Gray.png'),
    green: require('../images/ams/sensors/H2S/H2S-Green.png'),
    orchid: require('../images/ams/sensors/H2S/H2S-Orchid.png'),
    pink: require('../images/ams/sensors/H2S/H2S-Pink.png'),
    red: require('../images/ams/sensors/H2S/H2S-Red.png'),
    yellow: require('../images/ams/sensors/H2S/H2S-Yellow.png'),
  } as ColoredIcons,
  NO: {
    blue: require('../images/ams/sensors/NO/NO-Blue.png'),
    brown: require('../images/ams/sensors/NO/NO-Brown.png'),
    cornsilk: require('../images/ams/sensors/NO/NO-Cornsilk.png'),
    cyan: require('../images/ams/sensors/NO/NO-Cyan.png'),
    dark_blue: require('../images/ams/sensors/NO/NO-DarkBlue.png'),
    gold: require('../images/ams/sensors/NO/NO-Gold.png'),
    gray: require('../images/ams/sensors/NO/NO-Gray.png'),
    green: require('../images/ams/sensors/NO/NO-Green.png'),
    orchid: require('../images/ams/sensors/NO/NO-Orchid.png'),
    pink: require('../images/ams/sensors/NO/NO-Pink.png'),
    red: require('../images/ams/sensors/NO/NO-Red.png'),
    yellow: require('../images/ams/sensors/NO/NO-Yellow.png'),
  } as ColoredIcons,
  NO2: {
    blue: require('../images/ams/sensors/NO2/NO2-Blue.png'),
    brown: require('../images/ams/sensors/NO2/NO2-Brown.png'),
    cornsilk: require('../images/ams/sensors/NO2/NO2-Cornsilk.png'),
    cyan: require('../images/ams/sensors/NO2/NO2-Cyan.png'),
    dark_blue: require('../images/ams/sensors/NO2/NO2-DarkBlue.png'),
    gold: require('../images/ams/sensors/NO2/NO2-Gold.png'),
    gray: require('../images/ams/sensors/NO2/NO2-Gray.png'),
    green: require('../images/ams/sensors/NO2/NO2-Green.png'),
    orchid: require('../images/ams/sensors/NO2/NO2-Orchid.png'),
    pink: require('../images/ams/sensors/NO2/NO2-Pink.png'),
    red: require('../images/ams/sensors/NO2/NO2-Red.png'),
    yellow: require('../images/ams/sensors/NO2/NO2-Yellow.png'),
  } as ColoredIcons,
  O2: {
    blue: require('../images/ams/sensors/O2/O2-Blue.png'),
    brown: require('../images/ams/sensors/O2/O2-Brown.png'),
    cornsilk: require('../images/ams/sensors/O2/O2-Cornsilk.png'),
    cyan: require('../images/ams/sensors/O2/O2-Cyan.png'),
    dark_blue: require('../images/ams/sensors/O2/O2-DarkBlue.png'),
    gold: require('../images/ams/sensors/O2/O2-Gold.png'),
    gray: require('../images/ams/sensors/O2/O2-Gray.png'),
    green: require('../images/ams/sensors/O2/O2-Green.png'),
    orchid: require('../images/ams/sensors/O2/O2-Orchid.png'),
    pink: require('../images/ams/sensors/O2/O2-Pink.png'),
    red: require('../images/ams/sensors/O2/O2-Red.png'),
    yellow: require('../images/ams/sensors/O2/O2-Yellow.png'),
  } as ColoredIcons,
  SO2: {
    blue: require('../images/ams/sensors/SO2/SO2-Blue.png'),
    brown: require('../images/ams/sensors/SO2/SO2-Brown.png'),
    cornsilk: require('../images/ams/sensors/SO2/SO2-Cornsilk.png'),
    cyan: require('../images/ams/sensors/SO2/SO2-Cyan.png'),
    dark_blue: require('../images/ams/sensors/SO2/SO2-DarkBlue.png'),
    gold: require('../images/ams/sensors/SO2/SO2-Gold.png'),
    gray: require('../images/ams/sensors/SO2/SO2-Gray.png'),
    green: require('../images/ams/sensors/SO2/SO2-Green.png'),
    orchid: require('../images/ams/sensors/SO2/SO2-Orchid.png'),
    pink: require('../images/ams/sensors/SO2/SO2-Pink.png'),
    red: require('../images/ams/sensors/SO2/SO2-Red.png'),
    yellow: require('../images/ams/sensors/SO2/SO2-Yellow.png'),
  } as ColoredIcons,
  SW: {
    blue: require('../images/ams/sensors/SW/SW-Blue.png'),
    brown: require('../images/ams/sensors/SW/SW-Brown.png'),
    cornsilk: require('../images/ams/sensors/SW/SW-Cornsilk.png'),
    cyan: require('../images/ams/sensors/SW/SW-Cyan.png'),
    dark_blue: require('../images/ams/sensors/SW/SW-DarkBlue.png'),
    gold: require('../images/ams/sensors/SW/SW-Gold.png'),
    gray: require('../images/ams/sensors/SW/SW-Gray.png'),
    green: require('../images/ams/sensors/SW/SW-Green.png'),
    orchid: require('../images/ams/sensors/SW/SW-Orchid.png'),
    pink: require('../images/ams/sensors/SW/SW-Pink.png'),
    red: require('../images/ams/sensors/SW/SW-Red.png'),
    yellow: require('../images/ams/sensors/SW/SW-Yellow.png'),
  } as ColoredIcons,
  wifi: {
    blue: require('../images/wifi/wifi-blue.png'),
    red: require('../images/wifi/wifi-red.png'),
    gray: require('../images/wifi/wifi-gray.png'),
  } as ColoredIcons,
  alarm: {
    blue: require('../images/alarms/alarm-blue.png'),
    red: require('../images/alarms/alarm-red.png'),
    gray: require('../images/alarms/alarm-gray.png'),
    yellow: require('../images/alarms/alarm-yellow.png'),
    brown: require('../images/alarms/alarm-brown.png'),
    default: require('../images/alarms/alarm-default.png'),
    cornsilk: require('../images/alarms/alarm-cornsilk.png'),
    cyan: require('../images/alarms/alarm-cyan.png'),
    green: require('../images/alarms/alarm-green.png'),
    dark_blue: require('../images/alarms/alarm-dark_blue.png'),
    gold: require('../images/alarms/alarm-gold.png'),
    orchid: require('../images/alarms/alarm-orchid.png'),
    pink: require('../images/alarms/alarm-pink.png'),
    orange: require('../images/alarms/alarm-orange.png'),
  } as ColoredIcons,
  safeyeNodes: {
    default: require('../images/assets/asset-default.png'),
    blue: require('../images/assets/asset-blue.png'),
    brown: require('../images/assets/asset-brown.png'),
    cornsilk: require('../images/assets/asset-cornsilk.png'),
    cyan: require('../images/assets/asset-cyan.png'),
    green: require('../images/assets/asset-green.png'),
    dark_blue: require('../images/assets/asset-dark_blue.png'),
    gold: require('../images/assets/asset-gold.png'),
    gray: require('../images/assets/asset-gray.png'),
    orchid: require('../images/assets/asset-orchid.png'),
    pink: require('../images/assets/asset-pink.png'),
    red: require('../images/assets/asset-red.png'),
    yellow: require('../images/assets/asset-yellow.png'),
    orange: require('../images/assets/asset-orange.png'),
  } as ColoredIcons,
  vision: {
    blue: require('../images/other/camera-blue.png'),
    yellow: require('../images/other/camera-yellow.png'),
    red: require('../images/other/camera-red.png'),
  } as ColoredIcons,
  person: {
    blue: require('../images/other/user-blue.png'),
    yellow: require('../images/other/user-yellow.png'),
    red: require('../images/other/user-red.png'),
  } as ColoredIcons,
};

export const COLORED_LEAFLET_ICONS = mapValues(COLORED_ICONS, (i) =>
  mapValues(
    i,
    (iconUrl) =>
      new Icon({
        iconUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      })
  )
);

export const makeIconSmall = (icon: any) => {
  return cloneElement(icon, {sx: {fontSize: 16}});
};
