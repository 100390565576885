import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import last from 'lodash/last';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useGetMinerNetworkId} from '../../../hooks/get-miner-network-id';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  CommtracNodeByCnEvent,
  CommtracNodeByCnEventListQuery,
  CommtracNodeByCnEventListResponse,
} from '../../../interfaces/CommtracNodeByCnEvent';
import reduxActions from '../../../redux/actions';
import {getBooleanValue} from '../../../utils/boolean';
import {employeeMessageTypes} from '../../../utils/message-types';
import {
  handleChangeTab,
  handleCloseTab,
  handleOpenTab,
} from '../../../utils/tab';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import TabLabel from '../../common/TabLabel';
import TruncatedText from '../../common/TruncantedText';
import ObjectsByCommunicationNodeTrackingExportExcelButton from '../../communication-node/ObjectsByCommunicationNodeTrackingExportExcelButton';
import {DateTimeRangeSelect} from '../../selectors/DateTimeRangeSelect';
import MessageTypeSelect from '../../selectors/MessageTypeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: CommtracNodeByCnTrackingReportsData;
  onUpdate?: (value?: CommtracNodeByCnTrackingReportsData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'battery_voltage',
  'id',
  'nickname',
  'first_name',
  'last_name',
  'commtrac_external_id',
  'date',
  'message_type',
  'motion_sensor_value',
  'answer',
  'commtrac_current_zone_name',
  'strongest_cn_name',
];

interface CommtracNodeByCnTrackingReportsDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: number[];
  selectAll: boolean;
  params?: {
    date_start?: string | null;
    date_end?: string | null;
    by_strongest_cn_only?: boolean;
    event_type?: number[];
    asset_type?: ('miner' | 'asset')[];
    page?: number;
    limit?: number;
    order?: string | null;
    dir?: 'ASC' | 'DESC';
  };
}

export interface CommtracNodeByCnTrackingReportsData {
  activeId?: number;
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  openedItems: CommtracNodeByCnTrackingReportsDataTab[];
}

export const getCommtracNodeByCnTrackingReportsData =
  (): CommtracNodeByCnTrackingReportsData => ({
    activeId: undefined,
    openedItems: [],
    mapLayers: ['street', 'mine', 'commtrac_node_by_cn_history'],
    mapLevel: null,
  });

const getCommtracNodeByCnTrackingReportsDataTab = (
  id: number
): CommtracNodeByCnTrackingReportsDataTab => ({
  id,
  selectAll: true,
  params: {
    date_start: dayjs().format('YYYY-MM-DD 00:00:00'),
    date_end: dayjs().format('YYYY-MM-DD 23:59:59'),
  },
});

const TYPE_OPTIONS = [
  {value: 'miner', label: 'Employees'},
  {value: 'asset', label: 'Assets'},
];

export const CommtracNodeByCnTrackingReports = ({value, onUpdate}: Props) => {
  const reduxDispatch = useAppDispatch();

  const config = useMemo(
    () => ({
      ...(value ?? getCommtracNodeByCnTrackingReportsData()),
      mapLayers:
        value?.mapLayers ?? getCommtracNodeByCnTrackingReportsData().mapLayers,
    }),
    [value]
  );

  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex] &&
          config.openedItems[openedItemIndex].params
          ? config.openedItems[openedItemIndex]
          : getCommtracNodeByCnTrackingReportsDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const nodes = useAppSelector(({assets}) =>
    assets.nodes.filter(
      (i) => i.node_type === 'communication_node' && i.ack === '1'
    )
  );

  const tabNames = useMemo(
    () =>
      config.openedItems.map(
        (o) => nodes.find((i) => i.cn_external_id === o.id)?.name
      ),
    [config.openedItems, nodes]
  );

  // get fresh assets
  useEffect(() => {
    reduxDispatch(reduxActions.assets.fetchNodes);
  }, [config.openedItems]);

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<CommtracNodeByCnEventListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<CommtracNodeByCnEventListQuery | null>(
    () =>
      openedItem?.id
        ? {
            commtrac_external_id: openedItem?.id,
            date_start:
              openedItem.params?.date_start ??
              dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            date_end:
              openedItem.params?.date_end ??
              dayjs()
                .startOf('day')
                .add(1, 'day')
                .format('YYYY-MM-DD HH:mm:ss'),
            event_type: openedItem.params?.event_type ?? [],
            asset_type: openedItem.params?.asset_type ?? [],
            by_strongest_cn_only:
              openedItem.params?.by_strongest_cn_only ?? true,
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? null,
            dir: openedItem.params?.dir ?? 'DESC',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: CommtracNodeByCnEventListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const resp = await API.get<CommtracNodeByCnEventListResponse>(
          `${apiBaseUrl}/communication-node/events`,
          {params}
        );
        setFetchedData(resp.data);
        // collect all IDs
        if (openedItem?.selectAll) {
          openedItem.selectedIds = resp.data.items.map((el) => el.id);
        }
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/
  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);
  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*************/
  /* data grid */
  /*************/
  const getMinerNetworkId = useGetMinerNetworkId();
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<CommtracNodeByCnEvent>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'battery_voltage',
      renderHeader: () => (
        <Tooltip title="Battery Level">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: false,
      hideable: false,
      width: 50,
      valueGetter: ({row}) => {
        return row.battery_voltage ? row.battery_voltage / 10 : null;
      },
      renderCell: ({value}) => {
        if (value === null) {
          return <QuestionMarkIcon fontSize="small" />;
        }
        return <>{value}</>;
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'nickname',
      sortable: true,
      renderCell: ({row, value}) => {
        return (
          <TruncatedText
            color={getBooleanValue(row.alarm, 0, 1) ? 'error.main' : undefined}
            fontWeight={getBooleanValue(row.alarm, 0, 1) ? 'bold' : undefined}
          >
            {value?.toString()}
          </TruncatedText>
        );
      },
    },
    {
      field: 'first_name',
      sortable: true,
      renderCell: ({row, value}) => {
        return (
          <TruncatedText
            color={getBooleanValue(row.alarm, 0, 1) ? 'error.main' : undefined}
            fontWeight={getBooleanValue(row.alarm, 0, 1) ? 'bold' : undefined}
          >
            {value?.toString()}
          </TruncatedText>
        );
      },
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: false,
      valueGetter: ({row}) => {
        if (row.commtrac_external_id) {
          if (row.type === 2) {
            return getMinerNetworkId(row.commtrac_external_id);
          }
          return row.commtrac_external_id;
        }
      },
      renderCell: ({row, value}) => {
        return (
          <TruncatedText
            color={getBooleanValue(row.alarm, 0, 1) ? 'error.main' : undefined}
            fontWeight={getBooleanValue(row.alarm, 0, 1) ? 'bold' : undefined}
          >
            {value?.toString()}
          </TruncatedText>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sortable: true,
      renderCell: ({row, value}) => {
        return (
          <TruncatedText
            color={getBooleanValue(row.alarm, 0, 1) ? 'error.main' : undefined}
            fontWeight={getBooleanValue(row.alarm, 0, 1) ? 'bold' : undefined}
          >
            {value?.toString()}
          </TruncatedText>
        );
      },
    },
    {
      field: 'message_type',
      sortable: true,
      renderCell: ({row, value}) => {
        return (
          <TruncatedText
            color={getBooleanValue(row.alarm, 0, 1) ? 'error.main' : undefined}
            fontWeight={getBooleanValue(row.alarm, 0, 1) ? 'bold' : undefined}
          >
            {value?.toString()}
          </TruncatedText>
        );
      },
    },
    {
      field: 'motion_sensor_value',
      headerName: 'Motion State',
      sortable: true,
    },
    {
      field: 'answer',
      headerName: 'Status',
      sortable: true,
    },
    {
      field: 'commtrac_current_zone_name',
      headerName: 'Section',
      sortable: true,
    },
    {
      field: 'strongest_cn_name',
      headerName: 'Strongest Node',
      sortable: true,
    },
    {
      field: 'pos_lon',
      headerName: 'Longtitude',
      sortable: true,
    },
    {
      field: 'pos_lat',
      headerName: 'Latitude',
      sortable: true,
    },
    {field: 'beacon_data'},
  ];

  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);

  const dataGridRef = useRef<DataGridRef>(null);

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = openedItem?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems?.includes(i.id)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: rows?.map((i) => i.id) ?? [],
              },
              selectAll: {
                $set: true,
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: [],
              },
              selectAll: {
                $set: false,
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const openTab = (id: number) => {
    return handleOpenTab(id, config, onUpdate);
  };

  const closeTab = (id: number) => {
    return handleCloseTab(id, config, onUpdate);
  };

  const changeTab = (_: any, id: number) => {
    handleChangeTab(id, config, onUpdate);
  };

  return (
    <>
      <DashboardPanelTitleSlot>
        Objects by CN Tracking Reports
      </DashboardPanelTitleSlot>

      <ResizableColumns
        left={
          <Map
            panel="cn_tracking_report"
            selectedMapLayers={config.mapLayers}
            selectedLevel={config.mapLevel}
            commtracNodeByCnEventsData={selectedRows}
            minWidth={250}
            availableMapLayers={[
              'street',
              'mine',
              'commtrac_node_by_cn_history',
              'nodes',
              'alarms',
            ]}
            onSelectMapLayers={(v) => {
              onUpdate?.(
                update(config, {
                  mapLayers: {
                    $set: v,
                  },
                })
              );
            }}
            onSelectLevel={(v) => {
              onUpdate?.(
                update(config, {
                  mapLevel: {
                    $set: v,
                  },
                })
              );
            }}
          />
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            py={1.5}
            bgcolor={(theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
            }
          >
            {isOpenAwayFromConnectView && (
              <Box px={1.5}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button variant="outlined" {...bindTrigger(popupState)}>
                        Select an Object
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {nodes?.map((it) => (
                          <MenuItem
                            key={it.id}
                            onClick={() => {
                              it?.cn_external_id && openTab(it.cn_external_id);
                              popupState.close();
                            }}
                          >
                            {it.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {config.openedItems.length ? (
              <Box>
                <Tabs
                  value={openedItem?.id}
                  variant="scrollable"
                  onChange={changeTab}
                >
                  {config.openedItems.map((i, idx) => (
                    <Tab
                      key={i.id}
                      value={i.id}
                      label={
                        <TabLabel
                          name={tabNames?.[idx] ?? 'Undefined'}
                          onClose={() => closeTab(i.id)}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <Box minWidth={400} px={1.5}>
                <Alert severity="warning">No Communication Nodes Opened</Alert>
              </Box>
            )}

            <Box display="flex" flexDirection="column" px={1.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <Box minWidth={400}>
                    <DateTimeRangeSelect
                      value={
                        openedItem
                          ? [
                              dayjs(params?.date_start).toDate(),
                              dayjs(params?.date_end).toDate(),
                            ]
                          : undefined
                      }
                      size="small"
                      disabled={!openedItem}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    selectAll: true,
                                    params: {
                                      ...params,
                                      date_start: v?.[0]
                                        ? dayjs(v?.[0]).format(
                                            'YYYY-MM-DD HH:mm:ss'
                                          )
                                        : undefined,
                                      date_end: v?.[0]
                                        ? dayjs(v?.[1]).format(
                                            'YYYY-MM-DD HH:mm:ss'
                                          )
                                        : undefined,
                                    },
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />
                  </Box>

                  <MessageTypeSelect
                    value={params?.event_type}
                    size="small"
                    disabled={!openedItem}
                    options={employeeMessageTypes.filter((i) =>
                      [1, 12].includes(i.code)
                    )}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  selectAll: true,
                                  params: {
                                    ...openedItem.params,
                                    event_type: v?.length ? v : undefined,
                                  },
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />

                  <TextField
                    value={
                      !params?.asset_type?.length ? ['all'] : params.asset_type
                    }
                    disabled={!openedItem}
                    label="Type"
                    fullWidth
                    size="small"
                    select
                    SelectProps={{multiple: true}}
                    onChange={(e) => {
                      const v = e.target.value as unknown as (
                        | 'miner'
                        | 'asset'
                        | 'all'
                      )[];
                      const newValue =
                        last(v) === 'all'
                          ? undefined
                          : v.filter((i) => i !== 'all');
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  selectAll: true,
                                  params: {
                                    ...openedItem.params,
                                    asset_type: newValue as any,
                                  },
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  >
                    <MenuItem value="all">Both</MenuItem>
                    {TYPE_OPTIONS.map((i) => (
                      <MenuItem key={i.value} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormControlLabel
                    label="Strongest CN"
                    control={
                      <Checkbox
                        size="small"
                        checked={params?.by_strongest_cn_only ?? true}
                      />
                    }
                    disabled={!openedItem}
                    sx={{minWidth: 140}}
                    onChange={(_e, v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  selectAll: true,
                                  params: {
                                    ...openedItem.params,
                                    by_strongest_cn_only: v,
                                  },
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />
                </Box>

                <Box display="flex">
                  <ButtonGroup disabled={!openedItem}>
                    <Button size="small">
                      <ObjectsByCommunicationNodeTrackingExportExcelButton
                        componentProps={{...params}}
                      />
                    </Button>

                    <Button
                      size="small"
                      onClick={() => params && fetchData(params)}
                    >
                      <RefreshIcon />
                    </Button>

                    <AutoRefreshSelect
                      value={openedItem?.refreshInterval ?? null}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    refreshInterval: v,
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />

                    <Button
                      size="small"
                      onClick={() => dataGridRef.current?.printTable()}
                    >
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <Backdrop
            open={fetchedInProgress}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors.map((error, idx) => (
            <Alert
              key={idx}
              severity="error"
              onClose={() => params && fetchData(params)}
            >
              {error}
            </Alert>
          ))}

          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            loading={fetchedInProgress}
            shownFields={shownFields}
            pagination
            paginationMode="server"
            size="small"
            sortBy={
              params?.order
                ? {
                    field: params?.order,
                    dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                  }
                : null
            }
            sortingMode="server"
            page={params?.page}
            pageSize={params?.limit}
            rowCount={fetchedData?.count}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            onPageChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onPageSizeChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onSort={(v) => {
              if (v && openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          params: {
                            ...openedItem.params,
                            order: v.field,
                            dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onShownFieldsChange={setShownFields}
          />
        </Box>
      </ResizableColumns>
    </>
  );
};
