import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import _ from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  AmsEmoduleInstallationHistoryQuery,
  AmsEmoduleInstallationHistoryResponse,
} from '../../../interfaces/AmsEmoduleInstallationHistory';
import {
  DashboardEntity,
  DashboardPanelData,
} from '../../../interfaces/Dashboard';
import {ExportField} from '../../../interfaces/Export';
import reduxActions from '../../../redux/actions';
import {
  handleChangeTab,
  handleCloseTab,
  handleOpenTab,
} from '../../../utils/tab';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {DataGridRef} from '../../common/DataGrid';
import {ResizableColumns} from '../../common/ResizableColumns';
import TabLabel from '../../common/TabLabel';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {AmsEmoduleObjectSelect} from '../../selectors/AmsEmoduleObjectSelect';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {AmsEmoduleInstallationHistoryExportButton} from './AmsEmoduleInstallationHistoryExportButton';
import {AmsEmoduleInstallationHistoryExportModal} from './AmsEmoduleInstallationHistoryExportModal';
import {AmsEmoduleInstallationHistoryGrid} from './AmsEmoduleInstallationHistoryGrid';
import {AmsEmoduleInstallationHistoryMap} from './AmsEmoduleInstallationHistoryMap';

export interface AmsEmoduleInstallationHistoryTabConfig {
  id?: number;
  refreshInterval?: number;
  selectedIds?: number[];
  shownFields?: string[];
  exportFields?: ExportField[];
  params?: AmsEmoduleInstallationHistoryQuery;
}

export interface AmsEmoduleInstallationHistoryConfig {
  activeId?: number;
  openedItems?: AmsEmoduleInstallationHistoryTabConfig[];
  mapLevel?: number;
  mapLayers?: string[];
}

interface AmsEmoduleInstallationHistoryData {
  viewType: 'amsEmoduleInstallationHistory';
  amsEmoduleInstallationHistory: AmsEmoduleInstallationHistoryConfig;
}

const getDefaultAmsEmoduleInstallationHistoryData =
  (): AmsEmoduleInstallationHistoryData => {
    return {
      viewType: 'amsEmoduleInstallationHistory',
      amsEmoduleInstallationHistory: {},
    };
  };

interface Props {
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value?: DashboardPanelData) => void;
}

export const AmsEmoduleInstallationHistory = (props: Props) => {
  const reduxDispatch = useAppDispatch();

  const isOpenAwayFromConnectView = !props.entities
    ?.map((e) => e.name)
    .includes('Connect View');
  const gridRef = useRef<DataGridRef>(null);
  const amsEmodules = useAppSelector(({assets}) => assets.ams_emodules);

  const [panel] = usePanel();

  const [fetchedData, setFetchedData] =
    useState<AmsEmoduleInstallationHistoryResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [exportModalOpened, setExportModalOpened] = useState(false);

  const viewData = _.isEmpty(props.value)
    ? getDefaultAmsEmoduleInstallationHistoryData()
    : (props.value as AmsEmoduleInstallationHistoryData);
  const [config, setConfig] = useState<AmsEmoduleInstallationHistoryConfig>({
    ...viewData.amsEmoduleInstallationHistory,
  });

  // We need to track activeId change passed from ConnectView->onOpenHistory handler
  useEffect(() => {
    viewData.amsEmoduleInstallationHistory.activeId &&
      viewData.amsEmoduleInstallationHistory.openedItems?.length &&
      viewData.amsEmoduleInstallationHistory.openedItems?.length > 1 &&
      openTab(viewData.amsEmoduleInstallationHistory.activeId);
  }, [viewData.amsEmoduleInstallationHistory.activeId]);

  const defaultTabConfig: AmsEmoduleInstallationHistoryTabConfig = {
    params: {
      date_start: dayjs().format('YYYY-MM-DD'),
      date_end: dayjs().format('YYYY-MM-DD'),
      limit: 50,
      page: 0,
    },
  };
  const openedItem = useMemo(
    () =>
      !config.activeId
        ? undefined
        : {
            ...defaultTabConfig,
            ...config.openedItems?.find((it) => it.id === config.activeId),
          },
    [config.openedItems, config.activeId]
  );

  const openedItemIndex = useMemo(
    () => config.openedItems?.findIndex((i) => i.id === openedItem?.id) ?? -1,
    [openedItem]
  );

  useEffect(() => {
    if (openedItem?.id) {
      fetchData();
    }
  }, [openedItem?.id, openedItem?.params]);

  useEffect(() => {
    if (openedItem?.id) {
      props?.onUpdate?.(
        update(viewData, {
          amsEmoduleInstallationHistory: {
            $set: config,
          },
        })
      );
    }
  }, [openedItem]);

  const tabNames = useMemo(
    () =>
      config.openedItems?.map(
        (o) =>
          `E-module #${amsEmodules.find((i) => Number(i.id) === Number(o.id))?.serial_number ?? 'unknown'}`
      ),
    [config.openedItems, amsEmodules]
  );

  const fetchData = useCallback(async () => {
    if (!openedItem?.id) {
      return;
    }

    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<AmsEmoduleInstallationHistoryResponse>(
        `${apiBaseUrl}/ams/emodule/${openedItem.id}/installation-history`,
        {params: openedItem.params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  }, [openedItem?.id, openedItem?.params]);

  const openTab = (id: number) => {
    return handleOpenTab(id, config, setConfig);
  };

  const closeTab = (id: number) => {
    return handleCloseTab(id, config, setConfig);
  };

  const changeTab = (_: any, id: number) => {
    handleChangeTab(id, config, setConfig);
  };

  // get fresh assets
  useEffect(() => {
    reduxDispatch(reduxActions.assets.fetchAmsEmodules);
  }, [config.openedItems]);

  useRefreshInterval(fetchData, openedItem?.refreshInterval);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="calc(100% - 38px)"
      >
        <DashboardPanelTitleSlot>
          {t(`panels.${panel?.code}`)}
        </DashboardPanelTitleSlot>

        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* Errors */}
        {fetchedErrors.map((error, idx) => (
          <Alert
            key={idx}
            severity="error"
            onClose={() => openedItem && fetchData()}
          >
            {error}
          </Alert>
        ))}

        {/* Filters */}
        <Box display="flex" flexDirection="column" gap={1} py={1}>
          {isOpenAwayFromConnectView && (
            <Box>
              <AmsEmoduleObjectSelect onChange={openTab} />
            </Box>
          )}
          <Box pb={2}>
            {config.openedItems?.length ? (
              <Tabs
                value={openedItem?.id}
                variant="scrollable"
                onChange={changeTab}
              >
                {config.openedItems.map((t, idx) => (
                  <Tab
                    key={t.id}
                    value={t.id}
                    label={
                      <TabLabel
                        name={tabNames?.[idx] ?? ''}
                        onClose={() => t.id && closeTab(t.id)}
                      />
                    }
                  />
                ))}
              </Tabs>
            ) : (
              <Alert severity="warning">No e-module object selected</Alert>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box maxWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(openedItem?.params?.date_start).toDate(),
                  dayjs(openedItem?.params?.date_end).toDate(),
                ]}
                disabled={!openedItem}
                onChange={(v) => {
                  if (openedItem) {
                    setConfig(
                      update(config, {
                        openedItems: {
                          [openedItemIndex]: {
                            $set: {
                              ...openedItem,
                              params: {
                                ...openedItem.params,
                                date_start: v?.[0]
                                  ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                  : undefined,
                                date_end: v?.[0]
                                  ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                  : undefined,
                              },
                            },
                          },
                        },
                      })
                    );
                  }
                }}
              />
            </Box>

            <Box display="flex">
              <ButtonGroup disabled={!openedItem}>
                <AmsEmoduleInstallationHistoryExportButton
                  size="small"
                  disabled={!openedItem}
                  onClick={() => setExportModalOpened(true)}
                />

                <Button size="small" onClick={fetchData}>
                  <RefreshIcon />
                </Button>

                <AutoRefreshSelect
                  value={openedItem?.refreshInterval ?? null}
                  onChange={(v) => {
                    if (openedItem) {
                      setConfig(
                        update(config, {
                          openedItems: {
                            [openedItemIndex]: {
                              $set: {
                                ...openedItem,
                                refreshInterval: v as number,
                              },
                            },
                          },
                        })
                      );
                    }
                  }}
                />

                <Button
                  size="small"
                  onClick={() => gridRef.current?.printTable()}
                >
                  <PrintIcon />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>

        <ResizableColumns
          left={
            <Box pr="24px" height="100%">
              {/* Grid */}
              <AmsEmoduleInstallationHistoryGrid
                ref={gridRef}
                data={fetchedData}
                config={openedItem}
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      openedItems: {
                        [openedItemIndex]: {
                          $set: v,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
          }
        >
          {/* Map */}
          <AmsEmoduleInstallationHistoryMap
            data={fetchedData?.items.filter((i) =>
              openedItem?.selectedIds?.includes(i.id)
            )}
            config={config}
            onChange={(v) => {
              setConfig(
                update(config, {
                  mapLevel: {
                    $set: v.mapLevel,
                  },
                  mapLayers: {
                    $set: v.mapLayers,
                  },
                })
              );
            }}
          />
        </ResizableColumns>
      </Box>

      {exportModalOpened && (
        <AmsEmoduleInstallationHistoryExportModal
          value={openedItem}
          opened={exportModalOpened}
          onClose={() => setExportModalOpened(false)}
          onSubmit={(exportFields) => {
            if (openedItem) {
              setConfig(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        exportFields,
                      },
                    },
                  },
                })
              );
            }
            setExportModalOpened(false);
          }}
        />
      )}
    </>
  );
};
