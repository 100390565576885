/* eslint-disable @typescript-eslint/no-unused-vars */

import {Backdrop, CircularProgress} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty, values} from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useConfiguration} from '../../../hooks/configuration';
import {AlarmModuleNodeListResponse} from '../../../interfaces/AlarmModuleNode';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import reduxActions from '../../../redux/actions';
import {
  OpenedEntity,
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {getMsFromS} from '../../../utils/datetime';
import AlarmItemUpsert from '../../alarm-nodes/AlarmItemUpsert';
import ModalDraggable from '../../common/ModalDraggable';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import AlarmLogReport, {
  AlarmLogReportData,
  getAlarmLogReportData,
} from '../ConnectView/AlarmLogReport';
import {DashboardHistoryReportType} from '../DashboardPanelItem';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface AlarmGridViewData {
  viewType: 'alarmLog';
  alarmLogs: AlarmLogReportData;
}

const getConnectViewData = (): AlarmGridViewData => ({
  viewType: 'alarmLog',
  alarmLogs: getAlarmLogReportData(),
});

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string,
    type: DashboardHistoryReportType
  ) => void;
}

export const AlarmGrid = (props: Props) => {
  const [config, setConfig] = useState<AlarmGridViewData>(
    !isEmpty(props.value)
      ? (props.value as AlarmGridViewData)
      : getConnectViewData()
  );
  const [panel] = usePanel();
  const [openedAlarmLogNode, setOpenedAlarmLogNode] = useState<OpenedEntity>(
    []
  );
  const reduxDispatch = useDispatch();

  const [fetchedAlarmLogData, setFetchedAlarmLogData] =
    useState<AlarmModuleNodeListResponse>();
  const [, setFetchedAlarmLogErrors] = useState<string[]>([]);
  const [fetchedAlarmLogDataInProgress, setFetchedAlarmLogDataInProgress] =
    useState(false);
  const fetchAlarmLogs = useCallback(async () => {
    setFetchedAlarmLogDataInProgress(true);
    setFetchedAlarmLogErrors([]);

    try {
      const params = {
        ...config.alarmLogs.params,
        status: 'all',
      };

      const resp = await API.get<AlarmModuleNodeListResponse>(
        `${apiBaseUrl}/alarm-module`,
        {params}
      );
      setFetchedAlarmLogData(resp.data);
      reduxActions.assets.setAssets(reduxDispatch, {
        alarm_modules: resp.data.items,
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedAlarmLogErrors(messages);
    } finally {
      setFetchedAlarmLogDataInProgress(false);
    }
  }, [config.alarmLogs.params]);

  useEffect(() => {
    fetchAlarmLogs();
    props.onUpdate?.({...config});
  }, [config.alarmLogs.params]);

  const alarmRefreshPeriod = useConfiguration(
    'alarm',
    'alarm_autorefresh_rate'
  );
  const alarmRefreshInterval = alarmRefreshPeriod?.value
    ? getMsFromS(+alarmRefreshPeriod?.value)
    : null;

  const fetchAlarmLogInterval = useRef<any>(null);

  useEffect(() => {
    if (!alarmRefreshInterval) {
      if (fetchAlarmLogInterval.current) {
        clearInterval(fetchAlarmLogInterval.current);
      }
      return;
    }

    fetchAlarmLogInterval.current = setInterval(() => {
      fetchAlarmLogs();
    }, alarmRefreshInterval);

    return () => {
      clearInterval(fetchAlarmLogInterval.current);
    };
  }, [alarmRefreshInterval, fetchAlarmLogs]);

  const handleOpenItem = (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => {
    if (!(id in openedAlarmLogNode)) {
      setOpenedAlarmLogNode({
        ...openedAlarmLogNode,
        [id]: {mode, id},
      });
    }
  };

  return (
    <>
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>
      <AlarmLogReport
        type="separate_panel"
        value={config.alarmLogs}
        onChange={(v) => {
          setConfig(
            update(config, {
              alarmLogs: {
                $set: v ?? getAlarmLogReportData(),
              },
            })
          );
        }}
        // locationCoordinates={locationCoordinates}
        onOpenItem={handleOpenItem}
        onOpenHistory={props.onOpenHistory}
        onRefresh={fetchAlarmLogs}
        loading={fetchedAlarmLogDataInProgress}
        fetchedData={fetchedAlarmLogData}
        // onChangeLocationCoordinates={setLocationCoordinates}
      />
      <Backdrop
        open={fetchedAlarmLogDataInProgress}
        sx={{position: 'absolute'}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {values(openedAlarmLogNode).map(({id, mode}) => (
        <ModalDraggable key={`alarm-${id}`} open={true}>
          {({isActive}) => (
            <AlarmItemUpsert
              pk={id}
              isActiveModal={isActive}
              mode={mode === 'chat' ? 'view' : mode}
              onPurged={() => {
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
              onOpenHistory={(item, type) => {
                props.onOpenHistory?.(item.id, 'alarm');
              }}
              onSubmitted={() => {
                fetchAlarmLogs();
              }}
              onClose={() => {
                setOpenedAlarmLogNode(
                  update(openedAlarmLogNode, {$unset: [id]})
                );
              }}
            />
          )}
        </ModalDraggable>
      ))}
    </>
  );
};
